import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import { PortableText } from "@portabletext/react";

const StyledMainContainer = styled.main`
  max-width: 64rem;
`;

// markup
const CreditsPage = ({ data, location }) => {
  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout location={location}>
      <StyledMainContainer>
        <title>Credits</title>
        <h1>Credits</h1>
        <PortableText value={site._rawCredit}></PortableText>
      </StyledMainContainer>
    </Layout>
  );
};

export default CreditsPage;

// page query
export const query = graphql`
  query CreditsPageQuery {
    site: sanitySiteSettings {
      _rawCredit
    }
  }
`;
